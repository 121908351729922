<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "Subscribe",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "4.3  Subscribe to Kloser",
          description: [
            {
              text: "1. To subscribe to Kloser, go to the “Account” at the bottom tab. Tap on the “Subscription”",
              imgType: true,
              img: "400.Account – empty – 1.png",
            },
            {
              text: "2. You can choose either a monthly or yearly billing cycle. You could save more if you opt in for a yearly plan. ",
              imgType: true,
              img: "695.Subscription-empty.png",
            },
            {
              text: "3. Tap on “Subscribe now” button to proceed ",
              imgType: false,
            },
            {
              text: "4. Select any existing payment method or you can add a new credit/debit card to proceed.",
              imgType: true,
              img: "696.Subscription - choose payment.png",
            },
            {
              text: "5. Tap “Next” to confirm the purchase",
              imgType: true,
              img: "697.Subscription - choose payment – 2.png",
            },
            {
              text: "6. You will receive a confirmation email. ",
              imgType: true,
              img: "698. Subscription - status- subscribed.png",
            },
          ],
          explanation: [
            {
              expTitle: "Note: ",
              expDesc: "Please keep in mind that our products are renewed automatically every month or every year, depending on the subscription you acquired. If you unsubscribe, you will still have access to your Kloser premium subscription until the end of the paid period.",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
